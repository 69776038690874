import { Action } from '@ngrx/store';
import { IndexFilter } from 'app/portal/page/page-item-filter-bar/index-filter/index-filter.model';
import { QuestionSortOrder } from 'app/portal/page/page-item-filter-bar/sorting-filter/question-sort-order.enum';
import { MenuItem, ProductStatus, ReportGroupModel } from 'app/shared/models';
import { FilterOption } from '../../portal/page/page-item-filter-bar/settings-filter/settings-filter.model';

export enum Type {
  INIT_PORTAL = '[REPORT] INIT_PORTAL',
  SET_ACTIVE_REPORT = '[REPORT] SET_ACTIVE_REPORT',
  SET_ACTIVE_REPORT_SUCCESS = '[REPORT] SET_ACTIVE_REPORT_SUCCESS',
  SET_ACTIVE_HIERARCHY = '[REPORT] SET_ACTIVE_HIERARCHY',
  GROUP_SELECTOR_CHANGE_GROUP = '[REPORT] GROUP_SELECTOR_CHANGE_GROUP',
  GROUP_SELECTOR_CHANGE_GROUP_SUCCESS = '[REPORT] GROUP_SELECTOR_CHANGE_GROUP_SUCCESS',
  SET_SETTINGS_FILTER = '[REPORT] SET_SETTINGS_FILTER',
  SET_SETTINGS_FILTER_SUCCESS = '[REPORT] SET_SETTINGS_FILTER_SUCCESS',
  SET_SORTING_FILTER = '[REPORT] SET_SORTING_FILTER',
  SET_SORTING_FILTER_SUCCESS = '[REPORT] SET_SORTING_FILTER_SUCCESS',
  SET_QUESTION_FILTER = '[REPORT] SET_QUESTION_FILTER',
  SET_QUESTION_FILTER_SUCCESS = '[REPORT] SET_QUESTION_FILTER_SUCCESS',
  SET_FREETEXT_QUESTION_FILTER = '[REPORT] SET_FREETEXT_QUESTION_FILTER',
  SET_FREETEXT_QUESTION_FILTER_SUCCESS = '[REPORT] SET_FREETEXT_QUESTION_FILTER_SUCCESS',
  SET_AREA_FILTER = '[REPORT] SET_AREA_FILTER',
  SET_AREA_FILTER_SUCCESS = '[REPORT] SET_AREA_FILTER_SUCCESS',
  SET_INDEX_FILTER = '[REPORT] SET_INDEX_FILTER',
  SET_INDEX_FILTER_SUCCESS = '[REPORT] SET_INDEX_FILTER_SUCCESS',
  SET_STATUS_FILTER = '[REPORT] SET_STATUS_FILTER',
  SET_STATUS_FILTER_SUCCESS = '[REPORT] SET_STATUS_FILTER_SUCCESS',
  SET_TONALITY_CATEGORY_FILTER = '[REPORT] SET_TONALITY_CATEGORY_FILTER',
  SET_TONALITY_CATEGORY_FILTER_SUCCESS = '[REPORT] SET_TONALITY_CATEGORY_FILTER_SUCCESS',
  SET_TONALITY_FILTER = '[REPORT] SET_TONALITY_FILTER',
  SET_TONALITY_FILTER_SUCCESS = '[REPORT] SET_TONALITY_FILTER_SUCCESS',
  INIT_REPORT_STATE = '[REPORT] INIT_REPORT_STATE',
  INVALIDATE = '[REPORT] INVALIDATE'
}
export class GroupSelectorChangeGroup implements Action {
  readonly type = Type.GROUP_SELECTOR_CHANGE_GROUP;
  constructor(public payload: { groupId: number, groupParameter?: { key: string, value: string | number }, hierarchyId?: number, groupCategory?: string }) { }
}
export class GroupSelectorChangeGroupSuccess implements Action {
  readonly type = Type.GROUP_SELECTOR_CHANGE_GROUP_SUCCESS;
  constructor(public payload: { groupId: number, hierarchyId?: number, groupCategory?: string }) { }
}
export class InitPortal implements Action {
  readonly type = Type.INIT_PORTAL;
  constructor() { }
}
export class SetActiveReport implements Action {
  readonly type = Type.SET_ACTIVE_REPORT;
  constructor(public payload: { reportPage: MenuItem }) { }
}

export class SetActiveReportSuccess implements Action {
  readonly type = Type.SET_ACTIVE_REPORT_SUCCESS;
  constructor(public payload: { reportPage: MenuItem }) { }
}

export class SetActiveHierarchy implements Action {
  readonly type = Type.SET_ACTIVE_HIERARCHY;
  constructor(public payload: { hierarchyId: number }) { }
}

export class SetSettingsFilter implements Action {
  readonly type = Type.SET_SETTINGS_FILTER;
  constructor(public payload: { settingsFilter: FilterOption[] }) { }
}

export class SetSettingsFilterSuccess implements Action {
  readonly type = Type.SET_SETTINGS_FILTER_SUCCESS;
  constructor(public payload: { settingsFilter: FilterOption[] }) { }
}

export class SetSortingFilter implements Action {
  readonly type = Type.SET_SORTING_FILTER;
  constructor(public payload: { sortingFilter: QuestionSortOrder }) { }
}

export class SetSortingFilterSuccess implements Action {
  readonly type = Type.SET_SORTING_FILTER_SUCCESS;
  constructor(public payload: { sortingFilter: QuestionSortOrder }) { }
}

export class SetQuestionFilter implements Action {
  readonly type = Type.SET_QUESTION_FILTER;
  constructor(public payload: { questionKeys: string[] }) { }
}

export class SetQuestionFilterSuccess implements Action {
  readonly type = Type.SET_QUESTION_FILTER_SUCCESS;
  constructor(public payload: { questionKeys: string[] }) { }
}

export class SetFreetextQuestionFilter implements Action {
  readonly type = Type.SET_FREETEXT_QUESTION_FILTER;
  constructor(public payload: string) { }
}

export class SetFreetextQuestionFilterSuccess implements Action {
  readonly type = Type.SET_FREETEXT_QUESTION_FILTER_SUCCESS;
  constructor(public payload: string) { }
}


export class SetAreaFilter implements Action {
  readonly type = Type.SET_AREA_FILTER;
  constructor(public payload: { categories: string[] }) { }
}

export class SetAreaFilterSuccess implements Action {
  readonly type = Type.SET_AREA_FILTER_SUCCESS;
  constructor(public payload: { categories: string[] }) { }
}
export class SetIndexFilter implements Action {
  readonly type = Type.SET_INDEX_FILTER;
  constructor(public payload: { indexFilter: IndexFilter }) { }
}

export class SetIndexFilterSuccess implements Action {
  readonly type = Type.SET_INDEX_FILTER_SUCCESS;
  constructor(public payload: { indexFilter: IndexFilter }) { }
}

export class InitReportState implements Action {
  readonly type = Type.INIT_REPORT_STATE;
  constructor(public payload: Partial<ReportGroupModel>) { }
}

export class SetStatusFilter implements Action {
  readonly type = Type.SET_STATUS_FILTER;
  constructor(public payload: { status: ProductStatus[] }) { }
}

export class SetStatusFilterSuccess implements Action {
  readonly type = Type.SET_STATUS_FILTER_SUCCESS;
  constructor(public payload: { status: ProductStatus[] }) { }
}

export class SetTonalityCategoryFilter implements Action {
  readonly type = Type.SET_TONALITY_CATEGORY_FILTER;
  constructor(public payload: { ids: number[] }) { }
}

export class SetTonalityCategoryFilterSuccess implements Action {
  readonly type = Type.SET_TONALITY_CATEGORY_FILTER_SUCCESS;
  constructor(public payload: { ids: number[] }) { }
}

export class SetTonalityFilter implements Action {
  readonly type = Type.SET_TONALITY_FILTER;
  constructor(public payload: { ids: string[] }) { }
}

export class SetTonalityFilterSuccess implements Action {
  readonly type = Type.SET_TONALITY_FILTER_SUCCESS;
  constructor(public payload: { ids: string[] }) { }
}


export class Invalidate implements Action {
  readonly type = Type.INVALIDATE;
  constructor() { }
}

export type Actions = InitPortal | SetActiveReport | SetActiveReportSuccess | SetActiveHierarchy
  | GroupSelectorChangeGroup | GroupSelectorChangeGroupSuccess | SetSettingsFilter | SetSettingsFilterSuccess
  | SetSortingFilter | SetSortingFilterSuccess | SetQuestionFilter | SetQuestionFilterSuccess | SetFreetextQuestionFilter | SetFreetextQuestionFilterSuccess
  | SetAreaFilter | SetAreaFilterSuccess | SetIndexFilter | SetIndexFilterSuccess | SetStatusFilterSuccess
  | SetTonalityCategoryFilter | SetTonalityCategoryFilterSuccess| SetTonalityFilter | SetTonalityFilterSuccess
  | InitReportState | Invalidate;
