import { createSelector } from '@ngrx/store';
import { QuestionSortOrder } from 'app/portal/page/page-item-filter-bar/sorting-filter/question-sort-order.enum';
import { ReportState } from 'app/shared/models';
import { Actions as ReportAction, Type as ReportActionType } from 'app/state/actions/report.actions';
import { AppState } from '../app.state';
import { act } from '@ngrx/effects';


export const initialState: ReportState = {
  activeReportPage: {},
  activeHierarchyId: null,
  activeGroupId: null,
  activeGroupCategory: null,
  filter: {
    settings: [],
    sorting: QuestionSortOrder.Standard,
    questionKeys: null,
    freetextQuestionKey: null,
    indexes: {
      indexes: []
    },
    recommendationCategories: [],
    tonalityCategories: [],
    tonalities: []
  },
  invalidated: true
};

export const selectReportState = (state: AppState) => state.reportState;

export const selectActiveReportGroupIdd = createSelector(
  selectReportState,
  (state: ReportState) => state.activeGroupId
);

export const selectActiveReportPage = createSelector(
  selectReportState,
  (state: ReportState) => state.activeReportPage
);

export const selectActiveReportGroupCategory = createSelector(
  selectReportState,
  (state: ReportState) => state.activeGroupCategory
);



export function reducer(state: ReportState = initialState, action: ReportAction): ReportState {

  switch (action.type) {
    case ReportActionType.SET_ACTIVE_REPORT_SUCCESS: {
      return { ...state, activeReportPage: action.payload?.reportPage };
    }
    case ReportActionType.SET_ACTIVE_HIERARCHY: {
      return { ...state, activeHierarchyId: action.payload.hierarchyId };
    }
    case ReportActionType.GROUP_SELECTOR_CHANGE_GROUP_SUCCESS: {
      return { ...state, ...{ activeGroupId: action.payload.groupId, activeGroupCategory: action.payload.groupCategory, activeHierarchyId: action.payload.hierarchyId } };
    }
    case ReportActionType.SET_SETTINGS_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, settings: action.payload.settingsFilter } }
    }
    case ReportActionType.SET_SORTING_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, sorting: action.payload.sortingFilter } }
    }
    case ReportActionType.SET_QUESTION_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, questionKeys: action.payload.questionKeys } }
    }
    case ReportActionType.SET_FREETEXT_QUESTION_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, freetextQuestionKey: action.payload } }
    }
    case ReportActionType.SET_AREA_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, recommendationCategories: action.payload.categories } }
    }
    case ReportActionType.SET_INDEX_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, indexes: action.payload.indexFilter } }
    }
    case ReportActionType.SET_STATUS_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, statuses: action.payload.status } }
    }
    case ReportActionType.SET_TONALITY_CATEGORY_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, tonalityCategories: action.payload.ids } }
    }
    case ReportActionType.SET_TONALITY_FILTER_SUCCESS: {
      return { ...state, filter: { ...state.filter, tonalities: action.payload.ids } }
    }
    case ReportActionType.INVALIDATE: {
      return { ...state, invalidated: true }
    }
    case ReportActionType.INIT_REPORT_STATE: {
      return {
        ...state, ...{
          activeGroupId: action.payload.id ?? state.activeGroupId,
          activeGroupCategory: action.payload.category ?? state.activeGroupCategory,
          invalidated: false
        }
      }
    }
    default: {
      return state;
    }
  }

}
