import { Action } from '@ngrx/store';
import { ParameterItem, UserState } from 'app/shared/models';

export enum Type {
  LOAD_USER_STATE = '[USER_STATE] LOAD_USER_STATE',
  LOAD_USER_STATE_SUCCESS = '[USER_STATE] LOAD_USER_STATE_SUCCESS',
  UPDATE_USER_STATE_SUCCESS = '[USER_STATE] UPDATE_USER_STATE_SUCCESS',
  UPDATE_USER_STATE_ERROR = '[USER_STATE] UPDATE_USER_STATE_ERROR',
  UPDATE_PARAMETER = '[USER_STATE] UPDATE_PARAMETER',
  UPDATE_PARAMETER_SUCCESS = '[USER_STATE] UPDATE_PARAMETER_SUCCESS',
  UPDATE_PARAMETER_ERROR = '[USER_STATE] UPDATE_PARAMETER_ERROR',
  UPDATE_MULTIPLE_PARAMETERS = '[USER_STATE] UPDATE_MULTIPLE_PARAMETERS',
}

export class LoadUserState implements Action {
  readonly type = Type.LOAD_USER_STATE;
  constructor() { }
}
export class LoadUserStateSuccess implements Action {
  readonly type = Type.LOAD_USER_STATE_SUCCESS;
  constructor(public payload: UserState) { }
}
export class UpdateUserStateSuccess implements Action {
  readonly type = Type.UPDATE_USER_STATE_SUCCESS;
  constructor(public payload: UserState) { }
}
export class UpdateUserStateError implements Action {
  readonly type = Type.UPDATE_USER_STATE_ERROR;
  constructor(public payload: { error: Error }) { }
}

export class UpdateParameter implements Action {
  readonly type = Type.UPDATE_PARAMETER;
  constructor(public parameter: ParameterItem) { }
}

export class UpdateParameterError implements Action {
  readonly type = Type.UPDATE_PARAMETER_ERROR;
  constructor(public payload: { error: Error }) { }
}
export class UpdateParameterSuccess implements Action {
  readonly type = Type.UPDATE_PARAMETER_SUCCESS;
  constructor() { }
}
export class UpdateMultipleParameters implements Action {
  readonly type = Type.UPDATE_MULTIPLE_PARAMETERS;
  constructor(public parameters: ParameterItem[]) { }
}

export type Actions = LoadUserState |
  LoadUserStateSuccess |
  UpdateUserStateSuccess |
  UpdateUserStateError |
  UpdateParameter | UpdateParameterError | UpdateParameterSuccess;
